<template>
  <router-view />
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
  },
};
</script>

<style>
 .error-feedback {
  color: red;
 }
 .blur {
  filter: blur(8px);
}
</style>
